import React from 'react';
import LayoutES from '../components/layout.es';
import Seo from '../components/seo.es';

export default function AvisoLegal() {
	return (
		<LayoutES>
			<Seo title='Transparencia' />
			<div
				style={{
					margin: '0 auto',
					width: '80%',
					maxWidth: '800px',
					padding: '60px 0',
				}}
			>
				<h1>Transparency</h1>
				<p>Sorry, this information is only <a href="/es/transparency">in Spanish</a></p>
			</div>
		</LayoutES>
	);
}
